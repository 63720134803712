<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="subscribe"></company-submenu>

      <div class="submenu-content">
        <h2>Ma Labs Email Newsletter Subscription. Be The First To Know.</h2>
        <p class="mt-5 mb-5">Sign up for Ma Labs Email Newsletter to get updates on the lastest technology, new arrivals, exclusive offers, promotions and events. 
          Email Newsletter will be sent directly to your inbox.</p>

        <div v-if="message" class="alert alert-success">{{message}}</div>
        <div v-if="error" class="alert alert-danger">{{error}}</div>

        <form v-if="!message && !error" class="row g-3 " @submit.prevent="handleSubmit">
          <div class="col-md-10" :class="{'alert alert-danger': data.email.error}">
            <label class="form-label">Email Address <span class="text-danger">*</span></label>
            <div v-if="data.email.error">{{data.email.message}}</div>
            <input type="email" class="form-control" v-model="data.email.input" ref="email" maxlength="100">
          </div>

          <div class="col-md-10" :class="{'alert alert-danger': data.email2.error}">
            <label class="form-label">Retype Email Address <span class="text-danger">*</span></label>
            <div v-if="data.email2.error">It does not match the email address.</div>
            <input type="email" class="form-control" v-model="data.email2.input" ref="email2" maxlength="100">
          </div>

          <div class="col-md-3" :class="{'alert alert-danger': data.first_name.error}">
            <label class="form-label">First Name <span class="text-danger">*</span></label>
            <div v-if="data.first_name.error">This field is required</div>
            <input type="text" class="form-control" v-model="data.first_name.input" ref="first_name" maxlength="100">
          </div>

          <div class="col-md-3" :class="{'alert alert-danger': data.last_name.error}">
            <label class="form-label">Last Name <span class="text-danger">*</span></label>
            <div v-if="data.last_name.error">This field is required</div>
            <input type="text" class="form-control" v-model="data.last_name.input" ref="last_name" maxlength="100">
          </div>

          <div class="col-md-3">
            <label class="form-label">Title</label>
            <input type="text" class="form-control" v-model="data.title.input" ref="title" maxlength="100">
          </div>
          <div class="col-md-3"></div>
          <div class="col-md-3" :class="{'alert alert-danger': data.company.error}">
            <label class="form-label">Company Name <span class="text-danger">*</span></label>
            <div v-if="data.company.error">This field is required</div>
            <input type="text" class="form-control" v-model="data.company.input" ref="company" maxlength="100">
          </div>
           
          <div class="col-md-3" :class="{'alert alert-danger': data.branch.error}">
            <label class="form-label">Closest Ma Labs branch location <span class="text-danger">*</span></label>
            <div v-if="data.branch.error">This field is required</div>
            <select class="form-select" v-model="data.branch.input" ref="branch">
              <option value=""></option>
              <option value="San Jose"  >San Jose</option>
              <option value="Chicago"  >Chicago</option>
              <option value="Los Angeles"  >Los Angeles</option>
              <option value="New Jersey"  >New Jersey</option>
              <option value="Georgia"  >Georgia</option>
              <option value="Miami"  >Miami</option>
            </select>
          </div>

          <div class="col-md-3" :class="{'alert alert-danger': data.customer.error}">
            <label class="form-label">Are you an existing Ma Labs Customer? <span class="text-danger">*</span></label>
            <div v-if="data.customer.error">This field is required</div>
            <select class="form-select" v-model="data.customer.input" ref="customer">
              <option value=""></option>
              <option value="yes"  >Yes</option>
              <option value="no"  >No</option>
            </select>
          </div>

          <div v-if="data.customer.input == 'yes'" class="col-md-2" :class="{'alert alert-danger': data.customer_id.error}">
            <label class="form-label">Ma Labs Customer ID <span class="text-danger">*</span></label>
            <div v-if="data.customer_id.error">This field is required</div>
            <input type="text" class="form-control" v-model="data.customer_id.input" ref="customer_id" maxlength="20">
          </div>
          <div class="lead" style="padding-top:1.5rem!important">
            <p>•	We would like to learn more about you and your company so we can share more relevant product information and offers.</p>
            <p>•	Share your company information here if you would like us to connect you to more relevant solutions (or products) and offers.</p>
          </div>
          <!-- <div class="col-md-10">
            <label class="form-label">City</label>
            <input type="text" class="form-control" v-model="data.city.input">
          </div> -->

          <div class="col-md-3">
            <label class="form-label">State</label>
            <input type="text" class="form-control" v-model="data.state.input" maxlength="100">
          </div>

          <div class="col-md-3">
            <label class="form-label">Zip</label>
            <input type="text" class="form-control" v-model="data.zip.input" maxlength="20">
          </div>

          <div class="col-md-3">
            <label class="form-label">Country</label>
            <select class="form-select" v-model="data.country.input">
              <select-option-list-country />
            </select>
          </div>

          <div class="col-md-3"></div>

          <div class="col-md-3">
            <label class="form-label">Phone</label>
            <input type="text" class="form-control" v-model="data.phone.input" maxlength="50">
          </div>

          <div class="col-md-3">
            <label class="form-label">Industry</label>
            <select class="form-select" v-model="data.industry.input">
              <option value="" >----- Select a industry -----</option>
              <option value="Consultant">Consultant</option>
              <option value="Dealer">Dealer</option>
              <option value="Direct Market Reseller(DMR)">Direct Market Reseller(DMR)</option>
              <option value="E-Commerce/E-tailer">E-Commerce/E-tailer</option>
              <option value="Importer/Exporter">Importer/Exporter</option>
              <option value="Independ Software Vendor (ISV)">Independ Software Vendor (ISV)</option>
              <option value="OEM/ODM Technology Manufacturer">OEM/ODM Technology Manufacturer</option>
              <option value="Retailer">Retailer</option>
              <option value="Solution / Service Provider / Managed Service Provider">Solution / Service Provider / Managed Service Provider</option>
              <option value="System Integrator / System Builder">System Integrator / System Builder</option>
              <option value="Value-Added Reseller(VAR)">Value-Added Reseller(VAR)</option>
              <option value="Other">Other</option>
            </select>
          </div>

          

          <div class="col-md-10" >
            <label class="form-label">
             <input type="radio" checked> I want to receive Email Newsletters from Ma Labs containing special promotions and events, technology updates, and new product information.
            </label>
          </div>

          <div class="pt-4 pb-5">
            <span class="badge bg-primary">Note</span> You can unsubscribe at any time through our 
            <router-link :to="{name: 'Unsubscribe'}">Unsubscribe Page</router-link> or by clicking the unsubscribe button at the bottom of any Ma Labs Newsletter Email.
          </div>

          <div class="col-md-10">
            <button v-if="waiting" class="btn btn-primary btn-lg" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Submit
              </button>
            <button v-else type="submit" class="btn btn-primary btn-lg">Submit</button>
          </div>
        </form>

      </div>
    
  </div>

  <div id="google_recaptcha"></div>
</template>

<script>
import CompanySubmenu from '../../components/nav/CompanySubmenu.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import SelectOptionListCountry from "../../components/SelectOptionListCountry.vue"
import { ref, onMounted } from 'vue'
import { insertReCaptchaScript, handleReCaptcha, checkEmail } from '../../utils/common'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'EmailNewsletters',
  components: { CompanySubmenu, BreadcrumbPage, SelectOptionListCountry },
  setup() {
    const waiting = ref(false)
    const message = ref(null)
    const error = ref(null)
    const data = ref({
      subject: {input: null, error: false},
      first_name: {input: null, error: false},
      last_name: {input: null, error: false},
      email: {input: null, error: false, message: null},
      email2: {input: null, error: false},
      title: {input: null, error: false},
      company: {input: null, error: false},
      branch: {input: null, error: false},
      city: {input: null, error: false},
      state: {input: null, error: false},
      zip: {input: null, error: false},
      country: {input: null, error: false},
      phone: {input: null, error: false},
      industry: {input: null, error: false},
      customer: {input: null,  error: false},
      customer_id: {input: null, error: false},

    })
    const first_name = ref(null)
    const last_name = ref(null)
    const email = ref(null)
    const email2 = ref(null)
    const company = ref(null)
    const branch = ref(null)
    const customer = ref(null)
    const customer_id = ref(null)

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Email Newsletters", link: "EmailNewsletters"})

    //add script for google reCaptcha to template
    onMounted(() => {
        insertReCaptchaScript("google_recaptcha")
    })

    //form validation
    function validate() {
      let valid = true
      if (data.value.first_name.input != "" && data.value.first_name.input != null) data.value.first_name.error = false
      else {
        valid = false 
        data.value.first_name.error = true
        first_name.value.focus()
      }

      if (!!data.value.last_name.input) data.value.last_name.error = false
      else {
        valid = false        
        data.value.last_name.error = true
        last_name.value.focus()
      }

      if (!!data.value.email.input) {
        if (checkEmail(data.value.email.input) == true) {
          data.value.email.error = false
        }
        else {
          data.value.email.error = true
          data.value.email.message = "The email address is invalid."
        }
      }
      else {
        valid = false        
        data.value.email.error = true
        data.value.email.message = "The email address is required."
        email.value.focus()
      }

      if (data.value.email2.input == data.value.email.input) data.value.email2.error = false
      else {
        valid = false        
        data.value.email2.error = true
        email2.value.focus()
      }

      if (!!data.value.company.input) data.value.company.error = false
      else {
        valid = false        
        data.value.company.error = true
        company.value.focus()
      }

      if (!!data.value.branch.input) data.value.branch.error = false
      else {
        valid = false        
        data.value.branch.error = true
        branch.value.focus()
      }

      if (!!data.value.customer.input) data.value.customer.error = false
      else {
        valid = false        
        data.value.customer.error = true
        customer.value.focus()
      }

      if (data.value.customer.input == "yes" && !!data.value.customer_id.input) data.value.customer_id.error = false
      else if (data.value.customer.input == "yes" && !data.value.customer_id.input)
      {
        valid = false        
        data.value.customer_id.error = true
        customer_id.value.focus()
      }
      
      
      return valid
    }


    //submit form
    const handleSubmit = async () => {
      waiting.value =  true
      error.value = ""
      message.value = ""
      
      let result = await handleReCaptcha(grecaptcha)
      if (result["is_human"] == true) {
        //validate the form
        if (validate() == false) {
          waiting.value = false
          return 0
        }
        
        let payload = {
          first_name: data.value.first_name.input,
          last_name: data.value.last_name.input,
          email: data.value.email.input,
          company: data.value.company.input,
          branch: data.value.branch.input,
          title: data.value.title.input,
          customer: data.value.customer.input,
          custno: data.value.customer_id.input,
          city: data.value.city.input,
          state: data.value.state.input,
          zip: data.value.zip.input,
          country: data.value.country.input,
          phone: data.value.phone.input,
          industry: data.value.industry.input
        }
        
        getAPI.post('/account/email-newsletters/yes/', payload)
        .then((response) => {
          let res = response.data

          if (res["done"] == true) message.value = res["message"]
          else error.value = res["message"]
          
          waiting.value = false

          //scroll screen to top
          window.scrollTo(0,0);
        })
        .catch(err => {
          error.value = err
          waiting.value = false

          //scroll screen to top
          window.scrollTo(0,0);
        })
      }
      else {
        //if not pass google reCaptcha, show the error
        waiting.value = false
        error.value = result["message"]

        //scroll screen to top
        window.scrollTo(0,0);
      }

      
    }

    return { waiting,
              message,
              error, 
              data,
              first_name,
              last_name,
              email,
              email2,
              company,
              branch,
              customer,
              customer_id,
              handleSubmit,
              breadcrumb_links }
  }
}
</script>

<style>

</style>